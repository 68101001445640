const htmlLang = document.documentElement.lang;

// Detect browser setting language
const language = navigator.languages && navigator.languages[0] || // Chrome / Firefox
    navigator.language || // All browsers
    navigator.userLanguage; // IE <= 10
const protocol = (window.location.host.split(":")[0] !== 'localhost') ? 'https://' : 'http://';
if (htmlLang === 'ja' && !["ja", "ja-JP"].includes(language)) {
    window.location.href = protocol + window.location.host + '/en';
}

import axios from 'axios';
import Isotope from 'isotope-layout';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import '../styles/index.scss';

// loads the Icon plugin
UIkit.use(Icons);
// components can be called from the imported UIkit reference
// UIkit.notification('Hello world.');

const productFilters = new Isotope('#product-items', {
    itemSelector: '.product',
    filter: '*',
    layoutMode: 'fitRows',
});

const filters = document.querySelectorAll('#filters input');
filters.forEach(elem => {
    elem.addEventListener('change', onChangeFilter, false);
});


function onChangeFilter(evt) {
   
    const isChecked = evt.target.checked;
    const filterName = evt.target.value === '*' ? '*' : '.' + evt.target.value;
    
    if (isChecked) {
        productFilters.arrange({ filter: filterName });
    }
};


const instagramFeeds = document.querySelector('#instagramFeeds');
axios.get('https://www.instagram.com/explore/tags/korgvolca/?__a=1')
    .then((res) => {
        const resData = res.data;
        const edges = resData.graphql.hashtag.edge_hashtag_to_media.edges;
        for (let index = 0; index < 6; index++) {
            const feedData = edges[index];
            const {
                is_video,
                shortcode,
                thumbnail_src
            } = feedData.node;

            const newFeedHtml = `
                    <a href="https://www.instagram.com/p/${shortcode}/" target="_blank" class="instafeed uk-position-relative uk-display-block uk-width-1-2 uk-width-1-3@s uk-width-1-4@m uk-width-1-6@l">
                        ${is_video ? '<span uk-icon="video-camera" class="uk-position-top-right"></span>': ''}
                        <div>
                            <img src="${thumbnail_src}" style="width: 100%;">
                        </div>
                    </a>
                `;

            instagramFeeds.innerHTML = instagramFeeds.innerHTML + newFeedHtml;
        }
    });

window.onload = () => {
    productFilters.layout();
};

// Language selector
document.addEventListener('click', function (event) {

    // If the clicked element doesn't have the right selector, bail
    if (!event.target.matches('.language-selector')) return;

    // Don't follow the link
    event.preventDefault();

    const targetLang = event.target.getAttribute('target-lang');
    const protocol = (window.location.host.split(":")[0] !== 'localhost') ? 'https://' : 'http://';
    if (targetLang === 'ja') {
        window.location.href = protocol + window.location.host + '/';
    } else {
        window.location.href = protocol + window.location.host + '/en';
    }

}, false);

// // force HTTPS
// if (window.location.protocol !== 'https:' && window.location.host.split(":")[0] !== 'localhost') {
//     window.location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
// }